import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <header>
    <nav>
      <div className="logo"><Link to="/">W. Murray Butler, DPM</Link></div>
      <ul>
        <li><Link to="/services/">Services</Link></li>
        <li><Link to="/resources/">Resources</Link></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    </nav>
  </header>
)

export default Header
