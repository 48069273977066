import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <>
  <svg xmlns="http://www.w3.org/2000/svg" className="border" viewBox="0 0 1440 320" preserveAspectRatio="none">
    <path className="black" d="M0,160L80,176C160,192,320,224,480,234.7C640,245,800,235,960,224C1120,213,1280,203,1360,197.3L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
  </svg>
  <footer id="contact">
    <h2>Make an Appointment</h2>
    <p className="medium">With the team of experienced physicians and surgeons at Mid-South Foot &amp; Ankle Specialists,
    Dr. Butler provides orthopedic care to the communities of Memphis, Collierville, Germantown, Cordova, and Olive Branch.</p>
      <div className="location">
        <div className="map">
          <iframe 
            title="W. Murray Butler, Memphis Podiatrist" 
            loading="lazy" 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3264.27798554618!2d-89.85158958475704!3d35.09976888033391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd08e569a69d62609!2sDr.%20W.%20Murray%20Butler%2C%20Podiatrist!5e0!3m2!1sen!2sus!4v1600196161252!5m2!1sen!2sus"
          />
        </div>
        <div className="address">
          <p>
            <strong>Midsouth Foot &amp; Ankle Specialists</strong><br />
            The PennMarc Centre<br/>
            6401 Poplar Ave Suite 190<br />
            Memphis, TN 38119<br />
            Phone: (901) 309-7700
          </p>
          <p><strong>Hours of Operation</strong><br />
          8am – 4:30pm, Monday – Friday</p>
          <a className="button" href="http://msfas.com/contact/" target="_blank" rel="noreferrer">Book on msfas.com 
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
          </a>
        </div>
      </div>
  </footer>
  <span className="copyright">© {new Date().getFullYear()} W. Murray Butler, DPM. <Link to="legal">Privacy Policy and Terms of Use »</Link></span>
  </>
)

export default Footer
